import React from 'react';
import { ReactComponent as GatelysIcon } from '../../images/BYM_Gatelys.svg';
import { ReactComponent as TrafikklysIcon } from '../../images/BYM_trafikklys.svg';
import { ReactComponent as TrafikkskiltIcon } from '../../images/BYM_Trafikkskilt.svg';
import { ReactComponent as HullIVeienIcon } from '../../images/BYM_HulliVeien.svg';
import { ReactComponent as SoppelIcon } from '../../images/Soppel.svg';
import { ReactComponent as ToalettIcon } from '../../images/Toalett.svg';
import { ReactComponent as SnowIcon } from '../../images/snow.svg';
import { ReactComponent as RenholdIcon } from '../../images/renhold.svg';
import { ReactComponent as TreeIcon } from '../../images/tree.svg';
import { ReactComponent as BomIcon } from '../../images/BYM_Bom.svg';
import { ReactComponent as Kumlokk } from '../../images/BYM_Kumlokk.svg';
import { ReactComponent as BlomsterIcon } from '../../images/BYM_BiolgiskMangfold.svg';
import { ReactComponent as LawnmowerIcon } from '../../images/icon-gressklipper.svg';
import { ReactComponent as OverNightingIcon } from '../../images/icon-overnatting.svg';
import { ReactComponent as LadestolperIcon } from '../../images/Elbil.svg';
import { ReactComponent as BetalingsautomaterIcon } from '../../images/Billetter.svg';
import { ReactComponent as RuskenIcon } from '../../images/rusken.svg';
import { ReactComponent as DefaultIcon } from '../../images/character-exclamation-mark.svg';

import FaIcon from '../common/icons/FaIcon';
import Icon from '../common/icons/Icon';

const getHovedkategoriIcon = (kategori: string, defaultIconWithoutCircle = false): JSX.Element => {
  switch (kategori.toLowerCase()) {
    case 'gatelys':
      return <GatelysIcon title={kategori} className="icon-gatelys" />;
    case 'trafikklys':
      return <TrafikklysIcon title={kategori} />;
    case 'skilt':
      return <TrafikkskiltIcon title={kategori} />;
    case 'hull i veien':
      return <HullIVeienIcon title={kategori} />;
    case 'toaletter':
      return <ToalettIcon title={kategori} />;
    case 'søppel':
      return <SoppelIcon title={kategori} />;
    case 'gressklipping':
      return <LawnmowerIcon title={kategori} />;
    case 'ulovlig overnatting':
      return <OverNightingIcon title={kategori} />;
    case 'renhold':
      return <RenholdIcon title={kategori} />;
    case 'lekeplass':
      return <Icon icon="swingset" title={kategori} />;
    case 'holdeplasser':
      return <Icon icon="bus" title={kategori} />;
    case 'bymøbel':
      return <Icon icon="park" title={kategori} />;
    case 'sykkel':
      return <Icon icon="bike" title={kategori} />;
    case 'bortkjøring av snø':
    case 'brøyting':
    case 'strøing':
      return <SnowIcon title={kategori} />;
    case 'trær':
      return <TreeIcon title={kategori} />;
    case 'skøytebane':
      return <FaIcon icon="skating" title={kategori} />;
    case 'badeplass':
      return <FaIcon icon="swimming-pool" title={kategori} />;
    case 'idrettsutstyr':
      return <FaIcon icon="futbol" title={kategori} />;
    case 'tagging':
      return <FaIcon icon="spray-can" title={kategori} />;
    case 'bommer':
      return <BomIcon title={kategori} />;
    case 'sluk, kummer og lokk':
      return <Kumlokk title={kategori} />;
    case 'problematiske fremmede arter':
      return <BlomsterIcon title={kategori} />;
    case 'ladestolper':
      return <LadestolperIcon title={kategori} />;
    case 'betalingsautomater':
      return <BetalingsautomaterIcon title={kategori} />;
    case 'rusken':
      return <RuskenIcon title={kategori} />;
    default:
      if (defaultIconWithoutCircle) {
        return <DefaultIcon className="icon-default" title={kategori} />;
      } else {
        return <Icon icon="exclamation-mark-circle" title={kategori} />;
      }
  }
};

export default getHovedkategoriIcon;
