import React from 'react';
import FormatDate from '../locale/FormatDate';
import { useIntl } from 'react-intl';
import { Melding } from './interfaces';
import { getStatus } from './meldingHelper';
import getHovedkategoriIcon from '../kategoriSok/kategoridata';
import Translate from '../locale/Translate';
import FolgereBadge from './FolgereBadge';

export interface MeldingslisteItemProps {
  melding: Melding;
  onSelected?: (meldingId: string) => void;
}

const MeldingslisteItem: React.FC<MeldingslisteItemProps> = (props: MeldingslisteItemProps) => {
  const { melding, onSelected } = props;
  const { formatMessage } = useIntl();

  const onClick = (): void => {
    if (onSelected) onSelected(melding.meldingId);
  };

  return (
    <li className="meldingsliste-item">
      <div
        role="button"
        className={`${onSelected ? 'hover:bg-blue-hover focus:bg-blue-focus clickable' : ''}`}
        tabIndex={onSelected ? 0 : -1}
        onClick={() => onClick()}
        onKeyPress={() => onClick()}
      >
        <div className="meldingsliste-item-icon">
          {getHovedkategoriIcon(melding?.meldingstype?.beskrivelse?.split('-')[0]?.trim())}
        </div>
        <div className="meldingsliste-item-content">
          <div className="meldingsliste-item-row">
            <div className="meldingsliste-item-meldingstype">{melding?.meldingstype?.beskrivelse}</div>
            <div className="meldingsliste-item-dato">
              <FormatDate date={melding.opprettetTid} />
            </div>
          </div>
          <div className="meldingsliste-item-row">
            <div className="meldingsliste-item-meldingsnummer">
              <Translate id="meldinger.list-item.label.messageno" fallback="" />
              <span>{` ${melding.lopeNummer}`}</span>
            </div>
          </div>
          <div className="meldingsliste-item-row">
            <div className="meldingsliste-item-adresse mobile-only:w-full ">{melding.adresse}</div>

            <div className="badges">
              {melding?.antallFolgere > 0 && (
                <FolgereBadge
                  antallFolgere={melding.antallFolgere}
                  title={formatMessage({
                    id: 'meldinger.list-item.antallFolgereBadge.title',
                  })}
                />
              )}
              <div className="meldingsliste-item-status status-badge whitespace-no-wrap">
                {getStatus(melding.meldingsStatus)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default MeldingslisteItem;
