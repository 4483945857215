import React, { useRef, useEffect, ReactNode, ReactNodeArray } from 'react';

interface FocusTrapProps {
  children: ReactNodeArray | ReactNode;
}

const FocusTrap = (props: FocusTrapProps): JSX.Element => {
  const ref = useRef<HTMLSpanElement>(null);
  const visible = (element: HTMLElement): boolean => {
    if (!element.offsetHeight && !element.offsetWidth) {
      return false;
    }
    if (getComputedStyle(element).visibility === 'hidden') {
      return false;
    }
    return true;
  };
  useEffect(() => {
    let firstElement: HTMLElement | undefined;
    let lastElement: HTMLElement | undefined;
    const considerElement = (element: HTMLElement): void => {
      // I want to use if (!element.checkVisibility()) return; but this is currently only supported in firefox and chrome browsers not safari
      if (!visible(element)) return;
      if (firstElement === undefined) firstElement = element;
      else lastElement = element;
    };
    if (ref.current != null) {
      for (let i = 0; i < ref.current.querySelectorAll<HTMLElement>('[tabindex]').length; i++) {
        considerElement(ref.current.querySelectorAll<HTMLElement>('[tabindex]')[i]);
      }
    }
    const handleKeyOnLast = (event: KeyboardEvent): void => {
      if (event.key !== 'Tab' || event.shiftKey) return;
      event.preventDefault();
      firstElement?.focus();
    };
    const handleKeyOnFirst = (event: KeyboardEvent): void => {
      if (event.key !== 'Tab' || !event.shiftKey) return;
      event.preventDefault();
      lastElement?.focus();
    };
    firstElement?.addEventListener('keydown', handleKeyOnFirst);
    lastElement?.addEventListener('keydown', handleKeyOnLast);
    return () => {
      firstElement?.removeEventListener('keydown', handleKeyOnFirst);
      lastElement?.removeEventListener('keydown', handleKeyOnLast);
    };
  }, [props.children]);

  return <span ref={ref}>{props.children}</span>;
};

export default FocusTrap;
