import React from 'react';
import './FolgereBadge.scss';
import FaIcon from '../common/icons/FaIcon';

export interface FolgereBadgeProps {
  antallFolgere: number;
  title?: string;
}

const FolgereBadge: React.FC<FolgereBadgeProps> = ({ antallFolgere, title }) => {
  return (
    <div className="folgere-badge" title={title}>
      <FaIcon icon="eye" />
      <span>{antallFolgere}</span>
    </div>
  );
};

export default FolgereBadge;
