import unitTest from './test.text.json';
import navbar from '../navbar/Navbar.txt.json';
import languagePicker from './LanguagePicker.txt.json';
import user from '../user/User.txt.json';
import validatation from '../../utlis/validate.txt.json';
import landingPage from '../landingPage/LandingPage.txt.json';
import summary from '../summary/Summary.txt.json';
import adressesok from '../adressesok/AdresseSok.txt.json';
import kart from '../generisk/GeneriskKart.txt.json';
import samtykke from '../samtykke/Samtykke.text.json';
import faq from '../faq/faq.text.json';
import objektinnmelding from '../objektinnmelding/Objektinnmelding.txt.json';
import kategorisok from '../kategoriSok/KategoriSok.txt.json';
import profile from '../profile/Profile.txt.json';
import contact from '../contact/contact.txt.json';

import { MeldingErrorTranslations } from '../meldinger/MeldingError';
import { cookiePopupTexts } from '../cookiePopup/CookiePopup';
import { InnmeldingsBekreftelseTranslations } from '../innmelding/InnmeldingsBekreftelse';
import { MeldingerTranslations } from '../meldinger/Meldinger';
import { GrunnkartVelgerTexts } from '../grunnkartvelger/GrunnkartVelger';
import { ModalTranslations } from '../common/modal/Modal';
import { SearchInputTexts } from '../common/search/SearchInput';
import { AppErrorBoundaryTexts } from '../common/AppErrorBoundary';
import { PrivateRouteTexts } from '../routes/PrivateRoute';
import { BrowserCheckerTexts } from '../browserChecker/BrowserChecker';
import { MeldingsdetaljerTexts } from '../meldinger/Meldingsdetaljer';
import { ServiceWorkerWrapperTranslations } from '../../ServiceWorkerWrapper';
import kategoribeskrivelser from '../kategorBeskrivelse/KategoriBeskrivelse.txt.js';

export default {
  ...unitTest.en,
  ...navbar.en,
  ...languagePicker.en,
  ...user.en,
  ...validatation.en,
  ...landingPage.en,
  ...summary.en,
  ...adressesok.en,
  ...kart.en,
  ...samtykke.en,
  ...faq.en,
  ...objektinnmelding.en,
  ...kategorisok.en,
  ...profile.en,
  ...contact.en,
  ...InnmeldingsBekreftelseTranslations.en,
  ...MeldingerTranslations.en,
  ...cookiePopupTexts.en,
  ...GrunnkartVelgerTexts.en,
  ...ModalTranslations.en,
  ...SearchInputTexts.en,
  ...kategoribeskrivelser.en,
  ...AppErrorBoundaryTexts.en,
  ...PrivateRouteTexts.en,
  ...MeldingErrorTranslations.en,
  ...BrowserCheckerTexts.en,
  ...MeldingsdetaljerTexts.en,
  ...ServiceWorkerWrapperTranslations.en,
};
