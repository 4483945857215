/* eslint-disable no-restricted-globals */
import React, { ReactNode } from 'react';
import Translate from '../locale/Translate';
import TextButton from './button/TextButton';

interface AppErrorBoundaryProps {
  children: ReactNode | ReactNode[];
}
interface AppErrorBoundaryState {
  hasError: boolean;
}

class AppErrorBoundary extends React.Component<AppErrorBoundaryProps, AppErrorBoundaryState> {
  constructor(props: AppErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): AppErrorBoundaryState {
    return { hasError: true };
  }

  render(): ReactNode | ReactNode[] {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className="container mt-3 p-3">
          <h1 className="heading-1  p-3">
            <Translate id="appErrorBoundary.header" fallback="En feil har opstaått" />
          </h1>
          <p className="text-2 p-3">
            <Translate
              id="appErrorBoundary.body"
              fallback="Det kan være at det er en feil i lenken du fulgte for å komme hit."
            />
          </p>
          <TextButton className="text-2 p-3" onClick={() => location.reload()}>
            <Translate id="appErrorBoundary.reload" fallback="Last inn siden på nytt." />
          </TextButton>
        </div>
      );
    }

    return children;
  }
}

export default AppErrorBoundary;

export const AppErrorBoundaryTexts = {
  no: {
    'appErrorBoundary.header': 'Beklager, det har oppstått en feil',
    'appErrorBoundary.body': 'Det kan være at det er en feil i lenken du fulgte for å komme hit.',
    'appErrorBoundary.home-link': 'Gå til startsiden',
    'appErrorBoundary.reload': 'Last inn siden på nytt',
  },
  en: {
    'appErrorBoundary.header': 'Sorry, an error has occurred',
    'appErrorBoundary.body': 'There may be an error in the link you followed to get here.',
    'appErrorBoundary.home-link': 'Go to homepage',
    'appErrorBoundary.reload': 'Reload page',
  },
};
