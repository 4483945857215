/* eslint-disable @typescript-eslint/no-explicit-any */

const LocalStoreUtils = (): {
  setItem: (key: string, value: any) => Promise<void>;
  getItem: (key: string) => Promise<any>;
} => {
  const localstoreName = 'bymelding-portal-store';
  // let localStorage: LocalForage;
  // if (typeof window !== 'undefined') {
  //   localStorage = localforage.createInstance({
  //     driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
  //     name: '',
  //   });
  // }

  const getItem = async (key: string): Promise<any> => {
    if (typeof window !== 'undefined') {
      try {
        return await JSON.parse(localStorage.getItem(`${localstoreName}-${key}`) || '');
      } catch (e) {
        return localStorage.getItem(`${localstoreName}-${key}`);
      }
    }
    return null;
  };

  const setItem = async (key: string, value: any): Promise<void> => {
    if (typeof window !== 'undefined') {
      try {
        return await localStorage.setItem(
          `${localstoreName}-${key}`,
          typeof value === 'object' ? JSON.stringify(value) : value
        );
      } catch (e) {
        // todo
      }
    }
    return undefined;
  };
  return { getItem, setItem };
};

export default LocalStoreUtils;
