import React, { useEffect, useState } from 'react';
import { LayersControl, TileLayer, TileLayerProps } from 'react-leaflet';
import './GrunnkartVelger.scss';
import Translate from '../locale/Translate';
import Button from '../common/button/Button';
import FaIcon from '../common/icons/FaIcon';
import CustomLeafletControl from './CustomLeafletControl';
import LocalStoreUtils from '../../utlis/LocalStoreUtils';

export interface Grunnkart extends TileLayerProps {
  key: string;
  displayName: string;
  disableIfKartverketIsDown: boolean;
}

const grunnkartOptions = [
  {
    key: 'grunnkart',
    displayName: 'Kart',
    disableIfKartverketIsDown: false,
    url: `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`,
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  },
  {
    key: 'detaljert_grunnkart',
    displayName: 'Norgeskart',
    disableIfKartverketIsDown: true,
    url: `https://{s}/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}`,
    subdomains: ['cache.kartverket.no', 'cache2.kartverket.no', 'cache3.kartverket.no'],
    attribution: '&copy; <a href="href="http://www.kartverket.no">Kartverket</a>',
  },
  {
    key: 'satelite_grunnkart',
    displayName: 'Flyfoto',
    disableIfKartverketIsDown: true,
    url: 'https://opencache.statkart.no/gatekeeper/gk/gk.open_nib_web_mercator_wmts_v2?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=topo4&STYLE=default&FORMAT=image/jpgpng&TILEMATRIXSET=GoogleMapsCompatible&tilematrix={getMatrix}&TileRow={y}&TileCol={x}',
    getMatrix: (data: { z: number }) => `${`0${data.z}`.slice(-2)}`,
    attribution: '&copy; <a href="https://www.norgeibilder.no/">Kartverket, NIBIO og Statens vegvesen</a>',
  },
];

export const getGrunnkartOptionFromKey = (key?: string): Grunnkart => {
  return grunnkartOptions.find((option) => option.key === key) || grunnkartOptions[0];
};

export const selctedBasemapStorageKey = 'selected_basemap';

const GrunnkartVelger = (): JSX.Element => {
  const [activeGrunnkart, setActiveGrunnkart] = useState(grunnkartOptions[0].key);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [kartverketIsDown, setKartverketIsDown] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleOpen = (): void => setOpen(!open);

  useEffect(() => {
    const getSelection = async (): Promise<void> => {
      const soredSelection = (await LocalStoreUtils().getItem(selctedBasemapStorageKey)) as string;
      if (soredSelection) setActiveGrunnkart(soredSelection);
    };
    getSelection();
  }, []);

  const storeSelectionInMemory = (key: string): void => {
    LocalStoreUtils().setItem(selctedBasemapStorageKey, key);
  };

  const onTileError = (): void => {
    if (activeGrunnkart === grunnkartOptions[0].key) {
      // setKartverketIsDown(true);
      setActiveGrunnkart(grunnkartOptions[1].key);
    }
  };

  return (
    <>
      <LayersControl position="topright">
        {grunnkartOptions.map((m: Grunnkart) => {
          return (
            <LayersControl.BaseLayer key={m.key} name={m.displayName} checked={activeGrunnkart === m.key}>
              <TileLayer
                {...m}
                eventHandlers={{
                  tileerror: onTileError,
                }}
              />
            </LayersControl.BaseLayer>
          );
        })}
      </LayersControl>
      <CustomLeafletControl position="bottomright">
        <div className="grunnkart-options-mobile">
          <Button onClick={toggleOpen} modifier="circle" padding="2">
            <FaIcon icon="map" />
          </Button>
        </div>
        <div className={`grunnkart-options ${open ? 'open' : 'closed'}`}>
          {grunnkartOptions.map((m: Grunnkart) => {
            return (
              m.key !== activeGrunnkart && (
                <button
                  key={m.key}
                  type="button"
                  disabled={kartverketIsDown && m.disableIfKartverketIsDown}
                  onClick={() => {
                    toggleOpen();
                    setActiveGrunnkart(m.key);
                    storeSelectionInMemory(m.key);
                  }}
                  aria-label={m.key === 'detaljert_grunnkart' ? 'Norgeskart' : 'Flyfoto kart'}
                  className={`
                  cursor-pointer
                  px-1 py-2
                  font-bold
                  border-1 border-solid border-white
                  flex items-center justify-center
                  grunnkart-option grunnkart-option-${m.key}

                `}
                >
                  <span className="grunnkart-options-button-text">
                    <Translate id={`grunnkartvelger.${m.key}`} />
                  </span>
                </button>
              )
            );
          })}
        </div>
      </CustomLeafletControl>
    </>
  );
};

export default GrunnkartVelger;

export const GrunnkartVelgerTexts = {
  no: {
    'grunnkartvelger.grunnkart': 'Kart',
    'grunnkartvelger.detaljert_grunnkart': 'Norgeskart',
    'grunnkartvelger.satelite_grunnkart': 'Flyfoto',
  },
  en: {
    'grunnkartvelger.grunnkart': 'Map',
    'grunnkartvelger.detaljert_grunnkart': 'Detailed',
    'grunnkartvelger.satelite_grunnkart': 'Satellite',
  },
};
