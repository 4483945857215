import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { OSGPattern, ColoredOSGPattern, SizeableOSGPattern } from '../../../interfaces/osgInterface';
import './Button.scss';

type Modifiers = 'circle';
type Colors = 'blue-dark' | 'green-dark' | 'green-light' | 'red' | 'yellow';

export interface LinkButtonProps
  extends OSGPattern<undefined, Modifiers>,
    ColoredOSGPattern<Colors>,
    LinkProps,
    SizeableOSGPattern {
  padding?: '1' | '2' | '3' | '4' | '5' | '6' | '7';
  external?: boolean;
  to: string;
}

function LinkButton({
  className,
  children,
  modifier = '',
  colorOption = 'blue-dark',
  osgSize = '7',
  padding = '3',
  external = false,
  to,
  ...rest
}: LinkButtonProps): JSX.Element {
  let bgColor = '';
  let color = '';

  switch (colorOption) {
    case 'red':
      bgColor = 'red';
      color = 'blue-dark';
      break;
    case 'yellow':
      bgColor = 'yellow';
      color = 'blue-dark';
      break;
    case 'green-light':
      bgColor = 'green-light';
      color = 'green-dark';
      break;
    case 'green-dark':
      bgColor = 'green-dark';
      color = 'green-light';
      break;
    default:
      bgColor = 'blue-dark';
      color = 'white';
      break;
  }

  const c = `
  flex items-center justify-center
        ${!modifier ? `border-0 bg-${bgColor} text-${color} visited:text-${color} ` : ``} 
        ${
          modifier === 'circle'
            ? `rounded-full h-16 w-16 border-0 bg-${bgColor} text-${color} visited:text-${color} `
            : ''
        }
        text-${osgSize}
        p-${padding}
        ${className}
        cursor-pointer
        oslo-common-button
        oslo-button
  `;

  return !external ? (
    <Link to={to} className={c} {...rest}>
      {children}
    </Link>
  ) : (
    <a className={c} href={to} {...rest}>
      {children}
    </a>
  );
}

// Whitelist classnames:
//   heading-1
//   heading-2
//   heading-3
//   heading-4
//   heading-5

export default LinkButton;
