/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, createContext, useContext } from 'react';

import { IntlProvider } from 'react-intl';
// import intlNB from 'react-intl/locale-data/nb';
// import intlEN from 'react-intl/locale-data/en';
import LocalStoreUtils from '../../utlis/LocalStoreUtils';
import nb from './nb';
import en from './en';

// addLocaleData([...intlEN, ...intlNB]);

interface LocaleConfig {
  locale: string;
  messages: any;
  available: string[];
  lCode: string;
}

export const nbConfig = {
  locale: 'nb',
  messages: nb,
  available: ['en'],
  lCode: 'nb-NO',
};

const enConfig = {
  locale: 'en',
  messages: en,
  available: ['nb'],
  lCode: 'en-US',
};

interface LocaleContext {
  onChangeLanguage: { (laocale: string): void };
  currentLocale: LocaleConfig;
  localeInitialized: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const LocaleContext = createContext({} as LocaleContext);

const LocaleProvider = (props: { defaultConfig: LocaleConfig; children: JSX.Element }): JSX.Element => {
  const { defaultConfig, children } = props;
  const [config, setConfig] = useState(defaultConfig);
  const [localeInitialized, setLocaleInitialized] = useState(false);
  const { getItem, setItem } = LocalStoreUtils();

  const setLocale = (l: string): void => {
    if (l === 'en') {
      setItem('locale', 'en');
      setConfig(enConfig);
    } else {
      setItem('locale', 'nb');
      setConfig(nbConfig);
    }
  };

  useEffect(() => {
    getItem('locale')
      .then((res) => {
        setLocale(res);
      })
      .catch(() => setConfig(defaultConfig))
      .finally(() => {
        setLocaleInitialized(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeLanguage = (lang: string): void => {
    setLocaleInitialized(false);
    try {
      setLocale(lang);
    } catch (err) {
      setConfig(defaultConfig);
    } finally {
      setLocaleInitialized(true);
    }
  };

  return (
    <IntlProvider locale={config.locale} messages={config.messages}>
      <LocaleContext.Provider value={{ onChangeLanguage, currentLocale: config, localeInitialized }}>
        {children}
      </LocaleContext.Provider>
    </IntlProvider>
  );
};

export default LocaleProvider;

export const useLocale = (): any => useContext(LocaleContext);
