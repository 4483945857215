import unitTest from './test.text.json';
import navbar from '../navbar/Navbar.txt.json';
import languagePicker from './LanguagePicker.txt.json';
import user from '../user/User.txt.json';
import validatation from '../../utlis/validate.txt.json';
import landingPage from '../landingPage/LandingPage.txt.json';
import summary from '../summary/Summary.txt.json';
import adressesok from '../adressesok/AdresseSok.txt.json';
import kart from '../generisk/GeneriskKart.txt.json';
import samtykke from '../samtykke/Samtykke.text.json';
import faq from '../faq/faq.text.json';
import objektinnmelding from '../objektinnmelding/Objektinnmelding.txt.json';
import kategorisok from '../kategoriSok/KategoriSok.txt.json';
import profile from '../profile/Profile.txt.json';
import contact from '../contact/contact.txt.json';

import { MeldingErrorTranslations } from '../meldinger/MeldingError';
import { GrunnkartVelgerTexts } from '../grunnkartvelger/GrunnkartVelger';
import { cookiePopupTexts } from '../cookiePopup/CookiePopup';
import { InnmeldingsBekreftelseTranslations } from '../innmelding/InnmeldingsBekreftelse';
import { MeldingerTranslations } from '../meldinger/Meldinger';
import { ModalTranslations } from '../common/modal/Modal';
import { SearchInputTexts } from '../common/search/SearchInput';
import { AppErrorBoundaryTexts } from '../common/AppErrorBoundary';
import { PrivateRouteTexts } from '../routes/PrivateRoute';
import { BrowserCheckerTexts } from '../browserChecker/BrowserChecker';
import { MeldingsdetaljerTexts } from '../meldinger/Meldingsdetaljer';
import { ServiceWorkerWrapperTranslations } from '../../ServiceWorkerWrapper';
import kategoribeskrivelser from '../kategorBeskrivelse/KategoriBeskrivelse.txt.js';

export default {
  ...unitTest.no,
  ...navbar.no,
  ...languagePicker.no,
  ...user.no,
  ...validatation.no,
  ...landingPage.no,
  ...summary.no,
  ...adressesok.no,
  ...kart.no,
  ...samtykke.no,
  ...faq.no,
  ...objektinnmelding.no,
  ...profile.no,
  ...contact.no,
  ...kategorisok.no,
  ...InnmeldingsBekreftelseTranslations.no,
  ...MeldingerTranslations.no,
  ...cookiePopupTexts.no,
  ...GrunnkartVelgerTexts.no,
  ...ModalTranslations.no,
  ...SearchInputTexts.no,
  ...kategoribeskrivelser.no,
  ...AppErrorBoundaryTexts.no,
  ...PrivateRouteTexts.no,
  ...MeldingErrorTranslations.no,
  ...BrowserCheckerTexts.no,
  ...MeldingsdetaljerTexts.no,
  ...ServiceWorkerWrapperTranslations.no,
};
