import React, { useReducer } from 'react';

export interface Meldingsfilter {
  ikkeBehandlet: boolean;
  nyligBehandlet: boolean;
}

export interface MeldingerState {
  aktivFane: 'mine' | 'fulgte';
  filter: Meldingsfilter;
}

export interface MeldingerAction {
  type: 'FILTER_TOGGLE_IKKE_BEHANDLET' | 'FILTER_TOGGLE_NYLIG_BEHANDLET' | 'FILTER_RENS_ALLE' | 'FANE_SET';
  payload?: any;
}

export const FILTER_TOGGLE_IKKE_BEHANDLET = 'FILTER_TOGGLE_IKKE_BEHANDLET';
export const FILTER_TOGGLE_NYLIG_BEHANDLET = 'FILTER_TOGGLE_NYLIG_BEHANDLET';
export const FILTER_RENS_ALLE = 'FILTER_RENS_ALLE';
export const FANE_SET = 'FANE_SET';

export const reducer = (state: MeldingerState, action: MeldingerAction): MeldingerState => {
  switch (action.type) {
    case FILTER_TOGGLE_IKKE_BEHANDLET:
      return { ...state, filter: { ...state.filter, ikkeBehandlet: !state.filter.ikkeBehandlet } };
    case FILTER_TOGGLE_NYLIG_BEHANDLET:
      return { ...state, filter: { ...state.filter, nyligBehandlet: !state.filter.nyligBehandlet } };
    case FILTER_RENS_ALLE:
      return { ...state, filter: { ikkeBehandlet: false, nyligBehandlet: false } };
    case FANE_SET:
      return { ...state, aktivFane: action.payload };
    default:
      throw new Error('Reducer - Missing action type');
  }
};

export const initialState: MeldingerState = {
  aktivFane: 'mine',
  filter: { ikkeBehandlet: false, nyligBehandlet: false },
};

export const MeldingerContext = React.createContext({
  state: initialState,
  dispatch: (() => 0) as React.Dispatch<MeldingerAction>,
});

interface Props {
  children: React.ReactNode;
}

const MeldingerStateProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <MeldingerContext.Provider value={{ state, dispatch }}>{children}</MeldingerContext.Provider>;
};

export default MeldingerStateProvider;
