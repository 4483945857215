import React from 'react';
import './ArrowButton.scss';

export interface OpenCloseArrow extends React.HTMLAttributes<HTMLDivElement> {
  up: boolean;
  upText: string;
  downText: string;
  clicked: () => void;
}

export default function ArrowButton({
  upText,
  downText,
  up = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clicked = () => {},
  className,
  ...rest
}: OpenCloseArrow): JSX.Element {
  return (
    <div
      className={`vis-detaljer ${className}`}
      role="button"
      tabIndex={0}
      onKeyUp={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.key === 'Enter') clicked();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        clicked();
      }}
      {...rest}
    >
      <span>{up ? upText : downText}</span>
      <i className={`vis-detaljer-arrow ${up ? 'up' : 'down'}`} />
    </div>
  );
}
