import React from 'react';
import BrowserChecker from '../browserChecker/BrowserChecker';
import Translate from '../locale/Translate';

const ContactPage = (): JSX.Element => {
  const contactEmail = (chunks: string): JSX.Element => {
    return <a href={`mailto:${chunks} subject='Tilbakemelding om bymelding.no'`}>{chunks}</a>;
  };
  return (
    <>
      <BrowserChecker />
      <div className="container mx-auto p-5 flex flex-col pb-5 text-3 justify-center items-stretch">
        <h1 className="heading-1 pb-5">
          <Translate id="contact.heading" fallback="Kontakt oss" />
        </h1>
        <p className="text-2 my-2">
          <Translate
            id="contact.intro"
            fallback="Opplever du som bruker av nettstedet at innholdet ikke fungerer som det skal, eller at språket er vanskelig å forstå? Vi setter stor pris på tilbakemeldinger om tilgjengelighet!"
          />
        </p>
        <p className="text-2 my-2">
          <Translate id="contact.emailPrompt" fallback="Send oss en epost" values={{ contactEmail: contactEmail }} />
        </p>
        <p className="text-2 my-2">
          <Translate
            id="contact.outro"
            fallback="Ditt bidrag hjelper oss med å gjøre nettstedet mer tilgjengelig og brukervennlig for alle."
          />
        </p>
      </div>
    </>
  );
};

export default ContactPage;
