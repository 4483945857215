import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Icon, { iconOptions } from '../common/icons/Icon';

const NavbarLinkButton = ({
  icon,
  text,
  className,
  to,
  children,
  ...rest
}: {
  icon?: iconOptions;
  text: string;
  className?: string;
  to: string;
  children?: ReactNode;
}): JSX.Element => (
  <Link
    className={`${className} mx-3
      flex flex-col items-center justify-center 
      bg-transparent border-0 
      font-light text-sm
      cursor-pointer 
      text-blue-dark visited:text-blue-dark 
      mobile-only:h-logo-small h-logo-large mobile-only:w-logo-small w-logo-large`}
    type="button"
    to={to}
    {...rest}
  >
    {icon && <Icon icon={icon} aria-label={text} role="button" />}
    {children && children}
    <span className="hover:underline mobile-only:hidden">{text}</span>
  </Link>
);

export default NavbarLinkButton;
