import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckSquare,
  faCoffee,
  faSkating,
  faSquareFull,
  faTree,
  faSwimmingPool,
  faSnowplow,
  faBroom,
  faFutbol,
  faSprayCan,
  faArrowCircleLeft,
  faQuestion,
  faMap,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faTag,
  faCalendarAlt,
  faPen,
  faCamera,
  faHandPointUp,
  faSearchPlus,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

export const initilizeFaLibrary = (): void => {
  library.add(
    faCheckSquare,
    faCoffee,
    faSkating,
    faSquareFull,
    faTree,
    faSwimmingPool,
    faSnowplow,
    faBroom,
    faFutbol,
    faSprayCan,
    faArrowCircleLeft,
    faQuestion,
    faMap,
    faMapMarkerAlt,
    faMapMarkedAlt,
    faTag,
    faCalendarAlt,
    faPen,
    faCamera,
    faHandPointUp,
    faSearchPlus,
    faEye
  );
};

const FaIcon = ({ ...props }: FontAwesomeIconProps): JSX.Element => {
  return <FontAwesomeIcon {...props} />;
};

export default FaIcon;
