import React from 'react';
import Translate from '../locale/Translate';
import { routes } from '../routes/BymeldingGlobalRoutes';
import { Link } from 'react-router-dom';

const MeldingError: React.FC = () => {
  return (
    <div className="container mx-auto text-2 text-center ">
      <h1 className="heading-1 m-3">
        <Translate id="meldingerror.heading" fallback="Vi kunne ikke finne meldingen" />
      </h1>
      <p className="m-3">
        <Link className="underline" to={routes.meldinger}>
          <Translate id="meldingerror.link" />
        </Link>
      </p>
    </div>
  );
};

export default MeldingError;

export const MeldingErrorTranslations = {
  no: {
    'meldingerror.heading': 'Beklager, vi finner ikke meldingen du leter etter',
    'meldingerror.link': 'Gå til listen over dine meldinger',
  },
  en: {
    'meldingerror.heading': "Sorry, we can't find the message you're looking for",
    'meldingerror.link':
      'The message you are requesting was not found. <toListLink> Go to the list of your messages </toListLink>',
  },
};
