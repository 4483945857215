import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import MeldingerStateProvider from '../meldinger/MeldingerContext';
import Spinner from '../common/spinner/Spinner';
import PrivateRoute from './PrivateRoute';
import FaqPage from '../faq/FaqPage';
import ContactPage from '../contact/ContactPage';

const Innmelding = lazy(() => import('../innmelding/Innmelding'));
const Meldinger = lazy(() => import('../meldinger/Meldinger'));
const Profile = lazy(() => import('../profile/Profile'));
const RestPasswordPage = lazy(() => import('../user/ResetPassword'));
const ActivateAccount = lazy(() => import('../user/ActivateAccount'));
const VilkaarPage = lazy(() => import('../samtykke/VilkaarPage'));
const LandingPage = lazy(() => import('../landingPage/LandingPage'));

export const routes = {
  home: '/',
  glemtPassord: '/glemt_passord',
  takkForRegistrering: '/takkForRegistrering',
  activate: '/aktivering/:key',
  activateWithPassword: '/passordaktivering/:key',
  setNewPassword: '/konto/passord/:key',
  kategorier: '/kategorier',
  myProfile: '/min_profil',
  innmelding: '/innmelding/*',
  meldinger: '/meldinger/*',
  eula: '/eula',
  faq: '/faq',
  contact: '/contact',
};

const BymeldingGlobalRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path={routes.activate}
        element={
          <LazylodedRoute>
            <ActivateAccount />
          </LazylodedRoute>
        }
      />
      <Route
        path={routes.activateWithPassword}
        element={
          <LazylodedRoute>
            <RestPasswordPage activateAccount />
          </LazylodedRoute>
        }
      />
      <Route
        path={routes.setNewPassword}
        element={
          <LazylodedRoute>
            <RestPasswordPage />
          </LazylodedRoute>
        }
      />
      <Route
        path={routes.innmelding}
        element={
          <LazylodedRoute>
            <Innmelding />
          </LazylodedRoute>
        }
      />
      <Route
        path={routes.eula}
        element={
          <LazylodedRoute>
            <VilkaarPage />
          </LazylodedRoute>
        }
      />
      <Route
        path={routes.faq}
        element={
          <LazylodedRoute>
            <FaqPage />
          </LazylodedRoute>
        }
      />
      {/* Private Routes */}
      <Route element={<PrivateRoute />}>
        <Route
          path={routes.myProfile}
          element={
            <LazylodedRoute>
              <Profile />
            </LazylodedRoute>
          }
        />
        <Route
          path={routes.meldinger}
          element={
            <MeldingerStateProvider>
              <LazylodedRoute>
                <Meldinger />
              </LazylodedRoute>
            </MeldingerStateProvider>
          }
        />
      </Route>

      <Route
        path={routes.eula}
        element={
          <LazylodedRoute>
            <VilkaarPage />
          </LazylodedRoute>
        }
      />
      <Route
        path={routes.faq}
        element={
          <LazylodedRoute>
            <FaqPage />
          </LazylodedRoute>
        }
      />
      <Route
        path={routes.contact}
        element={
          <LazylodedRoute>
            <ContactPage />
          </LazylodedRoute>
        }
      />
      <Route path={routes.home} element={<LandingPage />} />
    </Routes>
  );
};

const LazylodedRoute = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  return <Suspense fallback={<Spinner />}>{children}</Suspense>;
};

export default BymeldingGlobalRoutes;
