import React, { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

const Translate = (props: {
  id: string;
  fallback?: string;
  values?: any;
  children?: (nodes: ReactNode[]) => ReactElement<any, string | JSXElementConstructor<any>> | null;
}): JSX.Element => {
  const { id, fallback, values, children } = props;
  return (
    <FormattedMessage id={id} defaultMessage={fallback || id} values={values}>
      {children}
    </FormattedMessage>
  );
};

export default Translate;
