import React, { useState, ReactElement } from 'react';
import { isEmpty } from 'lodash-es';
import Translate from '../locale/Translate';

import VilkaarText from './VilkaarText';
import { useSamtykke } from './SamtykkeProvider';

import Modal, { ModalSection } from '../common/modal/Modal';
import OutlineButton from '../common/button/OutlineButton';

interface VilkaarPopupOnlyTextProps {
  toggleOpen?: () => void;
  isOpen?: boolean;
  children?: {
    openModalElement: ReactElement;
  };
}

export default function VilkaarPopupOnlyText({ isOpen, toggleOpen, children }: VilkaarPopupOnlyTextProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { vilkaar } = useSamtykke();
  const toggleOpeninside = (): void => setIsModalOpen((prev) => !prev);
  return (
    <>
      <Modal
        toggle={children?.openModalElement ? toggleOpeninside : toggleOpen}
        isOpen={children?.openModalElement ? isModalOpen : isOpen}
      >
        <ModalSection>
          {vilkaar && !isEmpty(vilkaar.vilkaarTekster) && <VilkaarText vilkaar={vilkaar.vilkaarTekster[0]} />}
        </ModalSection>
        <ModalSection>
          <OutlineButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (toggleOpen) toggleOpen();
            }}
          >
            <Translate id="samtykke.button.close" />
          </OutlineButton>
        </ModalSection>
      </Modal>
    </>
  );
}
