import React from 'react';
import { OSGPattern, ColoredOSGPattern, SizeableOSGPattern } from '../../../interfaces/osgInterface';
import './Button.scss';

type Modifiers = 'circle';
type Colors = 'blue-dark' | 'green-dark' | 'green-light' | 'red' | 'yellow';

type ButtonElementProps = JSX.IntrinsicElements['button'];

export interface ButtonProps
  extends OSGPattern<undefined, Modifiers>,
    ColoredOSGPattern<Colors>,
    ButtonElementProps,
    SizeableOSGPattern {
  padding?: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7';
}

// eslint-disable-next-line import/prefer-default-export
function Button({
  className,
  children,
  modifier = '',
  colorOption = 'blue-dark',
  osgSize = '7',
  padding = '3',
  disabled,
  ...rest
}: ButtonProps): JSX.Element {
  let bgColor = '';
  let color = '';

  switch (colorOption) {
    case 'red':
      bgColor = 'red';
      color = 'blue-dark';
      break;
    case 'yellow':
      bgColor = 'yellow';
      color = 'blue-dark';
      break;
    case 'green-light':
      bgColor = 'green-light';
      color = 'green-dark';
      break;
    case 'green-dark':
      bgColor = 'green-dark';
      color = 'green-light';
      break;
    default:
      bgColor = 'blue-dark';
      color = 'white';
      break;
  }

  /* 
    Whitelist-classnames: 
    bg-green
    bg-green-dark
    bg-green-light
    bg-red
    bg-yellow
    bg-blue-dark
    text-blue-dark
    text-green-dark
    text-green-light
    text-green
    text-white
  */

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      disabled={disabled}
      className={`
        flex items-center justify-center
        ${!modifier ? `border-0 bg-${bgColor} text-${color}` : ``} 
        ${modifier === 'circle' ? `rounded-full h-16 w-16 border-0 bg-${bgColor} text-${color}` : ''}
        text-${osgSize}
        p-${padding}
        ${disabled ? 'opacity-75 cursor-not-allowed' : ''}
        ${className}
        focus:outline-none
        cursor-pointer
        oslo-common-button
        oslo-button
      `}
      {...rest}
    >
      {children}
    </button>
  );
}

export default Button;
