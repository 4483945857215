import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { VilkaarTekst } from './SamtykkeReducer';
import ArrowButton from '../common/button/ArrowButton';
import './Vilkaar.scss';

interface VilkaarTextProps {
  vilkaar: VilkaarTekst;
}
export default function VilkaarText({ vilkaar }: VilkaarTextProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [seeLongVersion, setSeeLongVersion] = useState(false);

  return (
    <>
      {seeLongVersion ? (
        // eslint-disable-next-line react/no-danger
        <div
          className="vilkaar-page text-black"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: vilkaar.fullTekst,
          }}
        />
      ) : (
        // eslint-disable-next-line react/no-danger
        <div
          className="vilkaar-page text-black"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: vilkaar.tekst,
          }}
        />
      )}
      <ArrowButton
        className="d-inline"
        up={seeLongVersion}
        upText={formatMessage({ id: 'samtykke.button.showless' })}
        downText={formatMessage({ id: 'samtykke.button.showmore' })}
        clicked={() => {
          setSeeLongVersion((prev) => !prev);
        }}
      />
    </>
  );
}
