import React from 'react';
import { useIntl } from 'react-intl';
import { format, parseISO } from 'date-fns';
import { enUS, nb } from 'date-fns/locale';

interface FormatDate {
  formatString?: string;
  date: string;
}

export const defaultFormatString = 'dd.MM.yyyy HH:mm';

export const getDateString = (date: string, formatString: string, locale: string): string => {
  const l = locale === 'nb' ? nb : enUS;
  if (!date) return "";
  return format(parseISO(date), formatString, { locale: l });
};

const FormatDate = ({ formatString = defaultFormatString, date }: FormatDate): JSX.Element => {
  const { locale } = useIntl();
  return <span>{getDateString(date, formatString, locale)}</span>;
};

export default FormatDate;
