import React, { useContext, useRef } from 'react';
import Translate from '../locale/Translate';
import { MeldingerContext, FILTER_TOGGLE_IKKE_BEHANDLET, FILTER_TOGGLE_NYLIG_BEHANDLET } from './MeldingerContext';

type ButtonElementProps = JSX.IntrinsicElements['button'];

interface FilterChipProps extends ButtonElementProps {
  selected: boolean;
}

const FilterChip: React.FC<FilterChipProps> = ({
  onClick,
  selected,
  children,
  ...rest
}: FilterChipProps): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <button
      ref={buttonRef}
      className={`filter-chip ${selected ? 'selected' : ''}`}
      type="button"
      tabIndex={0}
      onClick={(e) => {
        if (buttonRef?.current) buttonRef.current.blur();
        if (onClick) onClick(e);
      }}
      {...rest}
    >
      {children}
    </button>
  );
};

const Meldingsfilter: React.FC = (): JSX.Element => {
  const { state, dispatch } = useContext(MeldingerContext);

  const toggleIkkeBehandlet = (): void => {
    dispatch({ type: FILTER_TOGGLE_IKKE_BEHANDLET });
  };

  const toggleNyligBehandlet = (): void => {
    dispatch({ type: FILTER_TOGGLE_NYLIG_BEHANDLET });
  };

  return (
    <div className="meldingsfilter flex flex-row flex-stretch justify-left items-center p-2 whitespace-no-wrap overflow-x-auto">
      <span id="meldingsfilter-label" className="mr-2">
        <Translate id="meldinger.filter.label" />
      </span>
      <FilterChip id="filter-button-ikkebehandlet" selected={state.filter.ikkeBehandlet} onClick={toggleIkkeBehandlet}>
        <Translate id="meldinger.filter.label.ikkebehandlet" />
      </FilterChip>
      <FilterChip selected={state.filter.nyligBehandlet} onClick={toggleNyligBehandlet}>
        <Translate id="meldinger.filter.label.nyligbehandlet" />
      </FilterChip>
    </div>
  );
};

export default Meldingsfilter;
