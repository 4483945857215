import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router';

export const ENABLE_LOCALE_KEY = 'enable_locale';
export const ENABLE_VARSEL = 'enable_varsel';

export const isFeatureEnabled = (key: string): boolean => {
  const cookie = Cookies.get(`bymelding_portal_${key}`);
  if (cookie && cookie.toLowerCase() === 'true') return true;
  return false;
};

export const setFeatureFlag = (key: string): void => {
  Cookies.set(`bymelding_portal_${key}`, 'true', { expires: 365 });
  // eslint-disable-next-line no-restricted-globals
  location.reload();
};

export const removeFeatureFlag = (key: string): void => {
  Cookies.set(`bymelding_portal_${key}`, 'false');
  // eslint-disable-next-line no-restricted-globals
  location.reload();
};

export const FeatureFlagHandler = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const flag = location.search?.split('=')?.[1];
      if (!isFeatureEnabled(flag) && location.search.indexOf('?set_ff=') === 0) {
        setFeatureFlag(flag);
      } else if (isFeatureEnabled(flag) && location.search.indexOf('?remove_ff=') === 0) {
        removeFeatureFlag(flag);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return children;
};
