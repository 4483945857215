import React, { forwardRef } from 'react';
import './Inputfield.scss';

export interface InputfieldProps extends React.HTMLProps<HTMLInputElement> {
  rootClassName?: string;
  error?: string;
  warning?: string;
  success?: string;
}

const Inputfield = forwardRef<HTMLInputElement, InputfieldProps>(
  ({ className, rootClassName, error, warning, success, label, name, readOnly, required, ...rest }, ref) => {
    let classNameInput = '';
    let classNameMsg = '';
    let msg = '';
    if (error) {
      classNameInput = 'border-state-danger focus:border-state-danger';
      classNameMsg = 'bg-state-danger';
      msg = error;
    } else if (warning) {
      classNameInput = 'border-state-warning focus:border-state-warning';
      classNameMsg = 'bg-state-warning';
      msg = warning;
    } else if (success) {
      classNameInput = 'border-state-success focus:border-state-success';
      classNameMsg = 'bg-state-success';
      msg = success;
    } else {
      classNameInput = readOnly
        ? 'bg-grey-light border-grey-dark cursor-not-allowed'
        : 'bg-white border-blue-dark hover:border-blue-hover hover:placeholder-blue-hover focus:border-blue-dark';
      classNameMsg = 'hidden';
    }

    return (
      <div className={`flex flex-col mb-3 ${rootClassName ?? ''}`}>
        <label className="text-4" htmlFor={name}>
          {label} {`${required ? ' *' : ''}`}
        </label>
        <input
          aria-label={label}
          name={name}
          aria-invalid={!!error}
          aria-errormessage={error}
          aria-required={required}
          className={`
            text-3  p-3 
            border-2 border-solid focus:border-4 
            rounded-none 
            inputfield ${classNameInput} ${className}
          `}
          readOnly={readOnly}
          ref={ref}
          {...rest}
        />
        <div className={`mt-2 p-3 ${classNameMsg}`}>{msg}</div>
      </div>
    );
  }
);

export default Inputfield;
