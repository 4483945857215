import { Meldingsstatus } from './interfaces';

export const getStatusText = (id: number, tekstmal: string | undefined | null): string | undefined | null => {
  if (id === 1 || id === 2 || id === 3) {
    return '';
  }
  if (id === 10 || id === 50 || id === 32) {
    return 'Se E-post for mer informasjon. Har du spørsmål? Send en e-post til postmottak@bym.oslo.kommune.no og henvis til meldingsnr.';
  }
  return tekstmal;
};

export const getStatus = (meldingstatus: Meldingsstatus): string => {
  if (meldingstatus) {
    switch (meldingstatus.foreldreId) {
      case 8:
        return 'Avslått';
      case 100:
        return 'Henvises ekstern';
      case 150:
      case 200:
        return 'Videresendt';
      case 300:
        return 'Videresendt';
      default:
        switch (meldingstatus.id) {
          case 1:
          case 2:
          case 3:
            return 'Ikke behandlet';
          case 4:
            return 'Under behandling';
          case 5:
          case 315:
          case 316:
            return 'Ferdig behandlet';
          case 71:
            return 'Utbedring bestilt';
          case 6:
            return 'Arbeid utført';
          case 7:
            return 'Duplikat';
          case 8:
            return 'Avslått';
          case 9:
          case 14:
          case 25:
          case 200:
          case 300:
            return 'Videresendt';
          case 13:
          case 100:
            return 'Henvises ekstern';
          default:
            return '';
        }
    }
  }
  return '';
};
