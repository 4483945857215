import React from 'react';
import MeldingslisteItem from './MeldingslisteItem';
import { Melding } from './interfaces';

export interface MeldingslisteProps {
  meldinger: Array<Melding>;
  onSelected?: (meldingId: string) => void;
}

const Meldingsliste: React.FC<MeldingslisteProps> = (props: MeldingslisteProps) => {
  const { meldinger, onSelected } = props;

  return (
    <ul className="meldingsliste">
      {meldinger.map((melding) => (
        <MeldingslisteItem key={melding.meldingId} melding={melding} onSelected={onSelected} />
      ))}
    </ul>
  );
};

export default Meldingsliste;
