import React from 'react';
import { SizeableOSGPattern } from '../../../interfaces/osgInterface';
import './OsgIcon.scss';

type IProps = JSX.IntrinsicElements['i'];

export type iconOptions =
  | 'chevron-right'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-up'
  | '24h'
  | 'backpack'
  | 'bike'
  | 'bus'
  | 'cafe'
  | 'calendar'
  | 'coin-stacks'
  | 'crane'
  | 'dog'
  | 'drone'
  | 'exclamation-mark-circle'
  | 'filter'
  | 'fire-emblem'
  | 'handicap'
  | 'house-heart'
  | 'location-pin'
  | 'magnifying-glass-big'
  | 'magnifying-glass-small'
  | 'menu'
  | 'minus-sign'
  | 'museum'
  | 'park'
  | 'plus-sign'
  | 'recycling'
  | 'restaurant'
  | 'swingset'
  | 'toilet'
  | 'tram'
  | 'user'
  | 'water-faucet'
  | 'x'
  | 'facebook'
  | 'instagram'
  | 'linked-in'
  | 'twitter'
  | 'heart-plus'
  | 'clock'
  | 'garbage'
  | 'tickets'
  | 'email'
  | 'chevron-thin-up'
  | 'chevron-thin-down'
  | 'elevator'
  | 'street-vendor'
  | 'coffee-cup'
  | 'defibrillator'
  | 'bathroom'
  | 'healthcare'
  | 'livingroom'
  | 'wardrobe'
  | 'bedroom';

export interface IconProps extends IProps, SizeableOSGPattern {
  icon: iconOptions;
}

/**
 * Represent OSG's icon palette. You can also use icons directly by osg-icon-[followed by the icons name].
 * Example osg-icon-exclamation-mark-circle.
 * You can use the text-{color} classes from main.css to color the icons, example: text-red makes icon red.
 */

export default function Icon({ icon, className, osgSize, ...rest }: IconProps): JSX.Element {
  return <i className={`osg-icon-${icon} ${osgSize ? `heading-${osgSize}` : ''} ${className}`} {...rest} />;
}
