import React from 'react';
import { useIntl } from 'react-intl';
import Icon from '../icons/Icon';
import './SearchInput.scss';

interface SearchInputProps extends React.HTMLProps<HTMLInputElement> {
  value: string;
  placeholder?: string;
  onChange?: any;
  // onInput?: any;
  onReset: () => void;
}

const SearchInput = ({ value, onChange, onReset, ...rest }: SearchInputProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <div className="sok-input">
      <div className="border-container flex flex-row flex-no-wrap items-center  border-3 border-solid border-blue-dark bg-white">
        <div className="w-full">
          <input
            className="w-full border-0 py-2 pl-3 mobile-only:pl-1 sok-input-textfield"
            value={value}
            onChange={onChange}
            // onInput={onInput}
            {...rest}
          />
        </div>
        <button
          type="button"
          tabIndex={-1}
          className={`bg-white border-0 p-2 pl-0 w-5 ${value ? 'cursor-pointer' : ''}`}
          onClick={onReset}
          disabled={!value}
          aria-label={
            value
              ? formatMessage({ id: 'searchinput.clear_search_buttonLabel' })
              : formatMessage({ id: 'searchinput.disabled_search_buttonLabel' })
          }
        >
          <Icon icon={value ? 'x' : 'magnifying-glass-big'} className="heading-3" />
        </button>
      </div>
    </div>
  );
};

export default SearchInput;

export const SearchInputTexts = {
  no: {
    'searchinput.disabled_search_buttonLabel': 'Søk i feltet til venstre',
    'searchinput.clear_search_buttonLabel': 'Nullstill søk',
  },
  en: {
    'searchinput.disabled_search_buttonLabel': 'Search in the box on the left',
    'searchinput.clear_search_buttonLabel': 'Reset search',
  },
};
