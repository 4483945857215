import React, { useContext } from 'react';
import useSWR from 'swr';
import { useApi } from '../../api/apiProvider';
import { Melding } from './interfaces';
import Meldingsliste from './Meldingsliste';
import Translate from '../locale/Translate';
import { ReactComponent as TakkSmilefjes } from '../../images/takkSmilefjes.svg';
import LinkButton from '../common/button/LinkButton';
import { MeldingerContext } from './MeldingerContext';
import Spinner from '../common/spinner/Spinner';

const MeldingerJegFolger: React.FC<{ onSelected?: (meldingId: string) => void }> = (props) => {
  const { onSelected } = props;
  const { urls, api } = useApi();
  const { state } = useContext(MeldingerContext);
  const urlMeldinger = urls.fulgtemeldinger(state.filter.ikkeBehandlet, state.filter.nyligBehandlet);

  const getMeldinger = async (url: string): Promise<Melding[]> => {
    const response = await api.get(url);
    const result = response?.data?.meldinger as Array<Melding>;
    return result;
  };

  const { data: meldinger, error } = useSWR(urlMeldinger, () => getMeldinger(urlMeldinger), {
    suspense: false,
  });

  if (error) {
    return <h1 className="heading-1">Det oppsto en feil ved lasting av meldinger</h1>;
  } else if (!meldinger) {
    return <Spinner />;
  }
  return (
    <div className="meldinger p-2 " role="tabpanel" id="mine-tab" aria-labelledby="mine">
      {meldinger.length > 0 ? (
        <Meldingsliste meldinger={meldinger} onSelected={onSelected} />
      ) : (
        <div
          className="flex flex-col h-full p-2 align-middle justify-evenly"
          role="tabpanel"
          id="mine-tab"
          aria-labelledby="mine"
        >
          <div className="my-3 flex flex-row justify-center">
            <TakkSmilefjes />
          </div>
          <h1 className="heading-2 text-center my-3">
            <Translate id="meldinger.no-messages.title" />
          </h1>
          <p className="text-3 text-center my-3">
            <Translate id="meldinger.no-messages.sub" />
          </p>
          <LinkButton className="my-3" to={'/innmelding'}>
            <Translate id="meldinger.no-messages.button" />
          </LinkButton>
        </div>
      )}
    </div>
  );
};

export default MeldingerJegFolger;
