import React from 'react';
import './Button.scss';

type ButtonElementProps = JSX.IntrinsicElements['button'];

// eslint-disable-next-line import/prefer-default-export
function TextButton({ className, children, disabled, ...rest }: ButtonElementProps): JSX.Element {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      disabled={disabled}
      className={`
        ${className}
        inline
        underline
        border-0
        p-0
        bg-transparent
        text-blue-dark
        ${disabled ? ' opacity-75 cursor-not-allowed ' : ''}
        cursor-pointer
        oslo-text-button
        oslo-button
        visisted:text-blue-dark
      `}
      {...rest}
    >
      {children}
    </button>
  );
}

export default TextButton;
