import React, { useContext, useRef } from 'react';
import { MeldingerContext, FANE_SET } from './MeldingerContext';
import Translate from '../locale/Translate';

const MeldingerNav: React.FC = (): JSX.Element => {
  const { state, dispatch } = useContext(MeldingerContext);

  const keys = {
    end: 35,
    home: 36,
    left: 37,
    right: 39,
    delete: 46,
  };

  const faner = [
    {
      id: 'mine',
      label: <Translate id="innmelding-bekreft.myMeldinger" />,
      ref: useRef<HTMLButtonElement>(null),
    },
    {
      id: 'fulgte',
      label: <Translate id="innmelding-bekreft.followingMeldinger" />,
      ref: useRef<HTMLButtonElement>(null),
    },
  ];

  const setFane = async (fane: string): Promise<void> => {
    dispatch({ type: FANE_SET, payload: fane });
  };

  const keyupHandler = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    const key = e.keyCode;
    const currentIndex = faner.findIndex((f) => f.id === (e.target as HTMLButtonElement).id);
    const lastIndex = faner.length - 1;
    const nextIndex = currentIndex < lastIndex ? currentIndex + 1 : 0;
    const nextElement = faner[nextIndex].ref.current;
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex;
    const prevElement = faner[prevIndex].ref.current;

    switch (key) {
      case keys.left:
        e.preventDefault();
        if (prevElement) prevElement.focus();
        break;

      case keys.right:
        e.preventDefault();
        if (nextElement) nextElement.focus();
        break;

      default:
        break;
    }
  };

  const keydownHandler = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    const key = e.keyCode;
    const lastIndex = faner.length - 1;
    const firstElement = faner[0].ref.current;
    const lastElement = faner[lastIndex].ref.current;

    switch (key) {
      case keys.end:
        e.preventDefault();
        if (lastElement) lastElement.focus();
        break;

      case keys.home:
        e.preventDefault();
        if (firstElement) firstElement.focus();
        break;

      default:
        break;
    }
  };

  return (
    <div role="tablist" className="flex flex-row flex-stretch justify-left align-left p-2" aria-label="Meldinger">
      {faner.map((fane) => (
        <button
          key={fane.id}
          ref={fane.ref}
          type="button"
          role="tab"
          aria-selected={state.aktivFane === fane.id ? 'true' : 'false'}
          aria-controls={`${fane.id}-tab`}
          id={fane.id}
          className={`flex items-center justify-center border border-1 border-solid border-gray-light bg-transparent text-7 p-3 focus:outline-none cursor-pointer fane-button ${
            state.aktivFane === fane.id ? 'active' : ''
          }`}
          tabIndex={state.aktivFane === fane.id ? undefined : -1}
          onClick={() => setFane(fane.id)}
          onKeyDown={keydownHandler}
          onKeyUp={keyupHandler}
        >
          <span>{fane.label}</span>
        </button>
      ))}
    </div>
  );
};

export default MeldingerNav;
