import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Button from '../common/button/Button';
import Icon from '../common/icons/Icon';
import Translate from '../locale/Translate';

import './BrowserChecker.scss';

const isIE = (): boolean => {
  // return true;
  return /Trident\/|MSIE/.test(window.navigator.userAgent);
};

const BrowserChecker = (): JSX.Element | null => {
  const duration = 300;

  const [dismissed, setDismissed] = useState(false);
  if (isIE()) {
    return (
      <CSSTransition timeout={duration} classNames="slide-in-transition" in={isIE() && !dismissed} unmountOnExit>
        <div className="bg-blue-light w-full text-5 p-3 pl-5 flex flex-row justify-center align-middle z-nav mobile-only:hidden">
          <div className="flex-grow flex flex-row justify-center align-middle">
            <a
              href="https://www.oslo.kommune.no/ie-varsel/"
              className="hover:text-blue-hover hover:underline text-blue-dark visited:text-blue-dark"
            >
              <h2 className="font-bold heading-4 inline">
                <Translate id="browserChecker.Head" fallback="Bytt nettleser" />
              </h2>
              <span>
                <Translate
                  id="browserChecker.IE_body"
                  fallback=" - Internet Explorer er utdatert og kan gi feil og dårligere brukeropplevelse."
                />
              </span>
            </a>
          </div>
          <div className="">
            <Button modifier="circle" padding="1" onClick={() => setDismissed(true)}>
              <Icon icon="x" className="" />
            </Button>
          </div>
        </div>
      </CSSTransition>
    );
  }
  return null;
};

export const BrowserCheckerTexts = {
  no: {
    'browserChecker.Head': 'Bytt nettleser',
    'browserChecker.IE_body': ' - Internet Explorer er utdatert og kan gi feil og dårligere brukeropplevelse',
  },
  en: {
    'browserChecker.Head': 'Switch browser',
    'browserChecker.IE_body': ' - Internet Explorer is outdated and can provide errors and poor user experience',
  },
};

export default BrowserChecker;
