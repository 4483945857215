import React from 'react';
import { ColoredOSGPattern, SizeableOSGPattern } from '../../../interfaces/osgInterface';
import './Button.scss';

type Colors = 'blue-dark' | 'yellow';

type ButtonElementProps = JSX.IntrinsicElements['button'];

export interface OutlineButtonProps extends ColoredOSGPattern<Colors>, ButtonElementProps, SizeableOSGPattern {
  padding?: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7';
}

// eslint-disable-next-line import/prefer-default-export
const OutlineButton = React.forwardRef<HTMLButtonElement, OutlineButtonProps>(
  (
    { className, children, colorOption = 'blue-dark', osgSize = '7', padding = '3', disabled, ...rest },
    ref
  ): JSX.Element => {
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        disabled={disabled}
        className={`
        border-2 border-solid border-${colorOption}
        bg-transparent
        text-blue-dark
        text-${osgSize}
        p-${padding}
        ${disabled ? 'opacity-75 cursor-not-allowed' : ''}
        ${className}
        cursor-pointer
        oslo-outline-button
        oslo-button
      `}
        {...rest}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

export default OutlineButton;
