import React, { useState, useEffect } from 'react';
import * as serviceWorker from './serviceWorkerRegistration';
import Button from './features/common/button/Button';
import Translate from './features/locale/Translate';

interface ServiceWorkerWrapperProps {
  children: React.ReactNode;
}

const ServiceWorkerWrapper = ({ children }: ServiceWorkerWrapperProps): JSX.Element => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>();

  const onServiceWorkerUpdate = (reg: ServiceWorkerRegistration): void => {
    setWaitingWorker(reg.waiting);
  };

  const update = (): void => {
    if (!waitingWorker) return;

    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setWaitingWorker(undefined);
    window.location.reload();
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((reg) => setWaitingWorker(reg.waiting));
    }
  }, []);

  return (
    <>
      {children}
      {waitingWorker && (
        <div className="fixed bottom-0 inset-x-0 z-modal text-center mx-auto max-w-full tablet:max-w-3/4 fullhd:max-w-1/2">
          <div className="mx-auto p-2 bg-yellow items-center text-blue-dark leading-none flex" role="alert">
            <span className="flex rounded-full bg-blue-dark uppercase px-2 py-1 text-white font-bold mr-3">
              <Translate id="serviceworkerwrapper.update-available.badge" />
            </span>
            <span className="font-semibold mr-2 text-left flex-auto">
              <Translate id="serviceworkerwrapper.update-available.title" />
            </span>
            <Button type="button" onClick={update}>
              <Translate id="serviceworkerwrapper.update-available.button" />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceWorkerWrapper;

export const ServiceWorkerWrapperTranslations = {
  no: {
    'serviceworkerwrapper.update-available.badge': 'Ny',
    'serviceworkerwrapper.update-available.title': 'En ny versjon av denne tjenesten er tilgjengelig',
    'serviceworkerwrapper.update-available.button': 'Oppdater',
  },
  en: {
    'serviceworkerwrapper.update-available.badge': 'New',
    'serviceworkerwrapper.update-available.title': 'A new version of this application is avaialble',
    'serviceworkerwrapper.update-available.button': 'Update',
  },
};
