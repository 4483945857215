import React, { forwardRef, ReactNode } from 'react';
import './checkbox.scss';

export interface InputFieldProps extends React.HTMLProps<HTMLInputElement> {
  rootClassName?: string;
  error?: string;
  warning?: string;
  success?: string;
  labelText?: ReactNode;
  children: React.ReactNode;
}

type CheckboxProps = InputFieldProps;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ type, label, className, rootClassName, children, required, error, ...rest }, ref) => {
    return (
      <div className={`checkbox ${rootClassName ?? ''}`}>
        <label className="checkbox-label focus:text-blue-focus">
          <input
            className={`${className} checkbox-label-custom-checkbox`}
            aria-required={required}
            aria-invalid={!!error}
            aria-errormessage={error}
            type={type || 'checkbox'}
            ref={ref}
            {...rest}
          />
          <span className="checkbox-label-text text-5 focus:text-blue-focus hover:text-blue-focus ml-1">
            {label ?? children}
            {`${required ? ' *' : ''}`}
          </span>
        </label>
      </div>
    );
  }
);

export default Checkbox;
