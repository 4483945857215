/* eslint-disable @typescript-eslint/no-explicit-any */
interface Vilkaar {
  id: string;
  vilkaarTekster: VilkaarTekst[];
  opprettetDato: string;
  sistEndretData: string;
}

export interface VilkaarTekst {
  id: string;
  tekst: string;
  fullTekst: string;
  spraak: string;
  vilkaarId: string;
  opprettetDato: string;
  sistEndretData: string;
}

export interface Samtykke {
  brukerId: string;
  id: string;
  vilkaarId: string;
  opprettetDato: string;
  sistEndretDato: string;
  klientId: string;
}

interface State {
  samtykkeInitialized: boolean;
  acceptedSamtykke: boolean;
  loadingVilkaar: boolean;
  loadingSamtykke: boolean;
  showConfirmReject: boolean;
  samtykke: Samtykke | undefined;
  vilkaar: Vilkaar | undefined;
}

export const initState: State = {
  samtykkeInitialized: false,
  acceptedSamtykke: false,
  loadingVilkaar: false,
  loadingSamtykke: false,
  showConfirmReject: false,
  samtykke: undefined,
  vilkaar: undefined,
};

export const UPDATE_STATE = 'SAMTYKKE_UPDATE_STATE';
export const RESET_STATE = 'SAMTYKKE_RESET_STATE';

export const samtykkeReducer = (state: State, action: { type: string; payload?: any }): State => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload };
    case RESET_STATE:
      return { ...initState };
    default:
      return state;
  }
};
