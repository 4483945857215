import React, { useContext } from 'react';
import { LocaleContext } from './LocaleProvider';
import Translate from './Translate';
import OutlineButton from '../common/button/OutlineButton';
import { isFeatureEnabled, ENABLE_LOCALE_KEY } from '../../utlis/FeatureFlagUtils';
import './LanguagePicker.scss';

const LanguagePicker = ({ tabIndex }: { tabIndex: number }): JSX.Element => {
  const useLocale = useContext(LocaleContext);

  const { onChangeLanguage, currentLocale } = useLocale;

  return (
    <>
      {isFeatureEnabled(ENABLE_LOCALE_KEY) && (
        <div className="languageSelector-container">
          {currentLocale.available.map((locale: string) => (
            <div className="languageSelector-element" key={locale}>
              {/* <Translate id="languagePicker.buttonLabel" fallback="Change lanaguage" /> */}
              <OutlineButton
                padding="1"
                onClick={() => {
                  onChangeLanguage(locale);
                }}
                tabIndex={tabIndex}
                // {...props}
              >
                <Translate id={`languagePicker.buttontxt-${locale}`} fallback={locale} />
              </OutlineButton>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default LanguagePicker;
