import React, { useState } from 'react';
import TextButton from '../common/button/TextButton';
import Modal, { ModalSection } from '../common/modal/Modal';
import Translate from '../locale/Translate';

const MeldingerFAQ: React.FC = () => {
  const ProcessFQA = (chunks: any[]): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <>
        <TextButton onClick={() => setIsModalOpen(!isModalOpen)}>{chunks}</TextButton>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            toggle={() => {
              setIsModalOpen(!isModalOpen);
            }}
            preventScroll
          >
            <ModalSection>
              <>
                <h3 className="heading-3 my-3">
                  <Translate id="innmelding-bekreft.faq.header" />
                </h3>
                <div className="w-full my-3 text-3 tablet:text-2">
                  <Translate id="innmelding-bekreft.faq.section1" />
                </div>
                <div className="w-full my-3 text-3 tablet:text-2">
                  <Translate id="innmelding-bekreft.faq.section2" />
                </div>
                <div className="w-full my-3 text-3 tablet:text-2">
                  <Translate id="innmelding-bekreft.faq.section3" />
                </div>
              </>
            </ModalSection>
          </Modal>
        )}
      </>
    );
  };
  return (
    <div className="flex flex-row flex-stretch justify-left align-left p-2 text-5">
      <Translate
        id="mine-meldinger-faq.text"
        values={{
          ProcessFQA: ProcessFQA,
        }}
      />
    </div>
  );
};

export default MeldingerFAQ;
