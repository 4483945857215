import React, { Suspense, useContext } from 'react';
import { Route, useNavigate, Routes } from 'react-router-dom';
import Spinner from '../common/spinner/Spinner';
import './Meldinger.scss';
import MineMeldinger from './MineMeldinger';
import MeldingerJegFolger from './MeldingerJegFolger';
import Meldingsdetaljer from './Meldingsdetaljer';
import ErrorBoundary from '../common/ErrorBoundary';
import MeldingError from './MeldingError';
import MeldingerNav from './MeldingerNav';
import Meldingsfilter from './Meldingsfilter';
import { MeldingerContext } from './MeldingerContext';
import MeldingerFAQ from './MeldingerFAQ';

const Meldinger: React.FC = () => {
  const navigate = useNavigate();

  const { state } = useContext(MeldingerContext);

  const onSelected = (meldingId: string): void => {
    navigate(`${meldingId}`);
  };

  const onFulgteSelected = (meldingId: string): void => {
    navigate(`fulgte/${meldingId}`);
  };

  //TODO sjekke alle som her brukte match

  return (
    <div className="container mx-auto meldinger">
      <Routes>
        <Route
          path={`/fulgte/:meldingId`}
          errorElement={<ErrorBoundary fallback={<MeldingError />} />}
          element={
            <Suspense fallback={<Spinner />}>
              <Meldingsdetaljer fulgte />
            </Suspense>
          }
        />
        <Route
          path={`/:meldingId`}
          errorElement={<ErrorBoundary fallback={<MeldingError />} />}
          element={
            <Suspense fallback={<Spinner />}>
              <Meldingsdetaljer />
            </Suspense>
          }
        />
        <Route
          path={`/`}
          element={
            <>
              <MeldingerNav />
              <MeldingerFAQ />
              <Meldingsfilter />
              {state.aktivFane === 'fulgte' ? (
                <ErrorBoundary fallback={<h1 className="heading-1">Det oppsto en feil ved lasting av meldinger</h1>}>
                  <Suspense fallback={<Spinner />}>
                    <MeldingerJegFolger onSelected={onFulgteSelected} />
                  </Suspense>
                </ErrorBoundary>
              ) : (
                <ErrorBoundary fallback={<h1 className="heading-1">Det oppsto en feil ved lasting av meldinger</h1>}>
                  <Suspense fallback={<Spinner />}>
                    <MineMeldinger onSelected={onSelected} />
                  </Suspense>
                </ErrorBoundary>
              )}
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default Meldinger;

export const MeldingerTranslations = {
  no: {
    'meldinger.no-messages.title': 'Du har ingen meldinger som samsvarer med valgte filtre',
    'meldinger.no-messages.sub': 'Start innmelding ved å trykke på knappen under.',
    'meldinger.no-messages.button': 'Meld inn sak',
    'meldinger.list-item.label.messageno': 'Meldingsnummer:',
    'meldinger.details.label.messageno': 'Meldingsnr.:',
    'meldinger.filter.label': 'Filtre:',
    'meldinger.filter.label.ikkebehandlet': 'Ikke behandlet',
    'meldinger.filter.label.nyligbehandlet': 'Nylig behandlet',
    'innmelding-bekreft.myMeldinger': 'Mine meldinger',
    'innmelding-bekreft.followingMeldinger': 'Meldinger jeg følger',
    'meldinger.list-item.antallFolgereBadge.title': 'Antall brukere som følger denne meldingen',
  },
  en: {
    'meldinger.no-messages.title': 'You have no registered cases matching the current filters.',
    'meldinger.no-messages.sub': 'Start a new report by clicking the button below',
    'meldinger.no-messages.button': 'Report case',
    'meldinger.list-item.label.messageno': 'Message number:',
    'meldinger.details.label.messageno': 'Message no.:',
    'meldinger.filter.label': 'Filters:',
    'meldinger.filter.label.ikkebehandlet': 'Not handled',
    'meldinger.filter.label.nyligbehandlet': 'Newly handled',
    'innmelding-bekreft.myMeldinger': 'My messages',
    'innmelding-bekreft.followingMeldinger': 'Messages I follow',
    'meldinger.list-item.antallFolgereBadge.title': 'Number of users following this message',
  },
};
